/* Add animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes scaleUp {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

/* Scroll animations */
.fade-in {
  animation: fadeIn 1s ease forwards;
}

/* Home Section Styling */
.home-section {
  text-align: center;
  padding: 3rem 2rem;
  background: #fff;
  color: #333;
  animation: fadeIn 1.5s ease forwards;
}

/* About Content Styling */
.about-content {
  padding: 3rem 1rem;
  max-width: 1200px;
  margin: auto;
  animation: fadeIn 1.5s ease forwards;
  background: none !important; /* Ensure background color is removed */
  box-shadow: none !important; /* Ensure box-shadow is removed */

}

.about-content h2 {
  color: #000;
  font-size: 2.5rem;
  position: relative;
  display: inline-block;
  margin: 0;
  animation: bounce 2s infinite;
}

.about-content p {
  text-align: start;
  font-size: 1.2rem;
  line-height: 1.8;
  margin: 1.5rem 0;
  color: #555;
}

.about-content h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 4px;
  width: 30%;
  background-color: #02faf9;
  transition: width 0.4s ease, transform 0.4s ease;
  transform-origin: left;
}

.about-content h2:hover::after {
  width: 100%;
  transform: scaleX(1);
}

/* Services Grid Styling */
.services-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  padding: 1rem;
  animation: fadeIn 1.5s ease forwards;
}

.services-grid .custom-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1 1 calc(33.333% - 2rem); /* Adjusted to include gap spacing */
  max-width: calc(33.333% - 2rem);
  box-sizing: border-box;
  padding: 2rem;
}

.services-grid .custom-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-card img {
  max-width: 100%;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.custom-card img:hover {
  transform: scale(1.1);
}

/* Responsive Design */
/* Responsive Design */
@media (max-width: 1200px) {
  .services-grid .custom-card {
    flex: 1 1 calc(33.333% - 1rem);
    max-width: calc(33.333% - 1rem);
  }
}

@media (max-width: 1000px) {
  .services-grid .custom-card {
    flex: 1 1 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
}

@media (max-width: 800px) {
  .services-grid .custom-card {
    flex: 1 1 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
}

@media (max-width: 670px) {
  .services-grid .custom-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}


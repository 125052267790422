.footer {
    background-color: #1a1a1a;
    color: #f5f5f5;
    padding: 20px 0;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-logo {
    margin-bottom: 15px;
}

.footer-logo img {
    width: 80px;
    height: auto;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.footer-logo img:hover {
    transform: scale(1.05);
    filter: brightness(1.2);
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    text-align: left;
}

.section {
    flex: 1 1 calc(50% - 20px);
    margin: 10px;
    padding: 0 15px;
    box-sizing: border-box;
}

.section h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #02faf9;
    font-weight: 700;
    position: relative;
    transition: color 0.3s ease;
}

.section h3::after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background-color: #02faf9;
    position: absolute;
    left: 0;
    bottom: -6px;
    transition: width 0.3s ease;
}

.section h3:hover::after {
    width: 80%;
}

.section p {
    margin-bottom: 5px;
    transition: color 0.3s ease;
    line-height: 1.4;
}

.section p:hover {
    color: #02faf9;
}

.vertical-line {
    width: 2px;
    background-color: #f5f5f5;
    height: 40px;
    margin: 0 10px;
}

.contact-info {
    display: flex;
    align-items: center;
    margin: 5px 0;
    text-align: left;
}

.contact-info img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    filter: invert(1);
    transition: transform 0.3s ease;
}

.contact-info img:hover {
    transform: rotate(360deg);
}

.social-icons {
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 10px;
    padding-top: 18px;
}

.social-icons .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 2px solid #f5f5f5;
    border-radius: 50%;
    background-color: #333;
    transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
}

.social-icons .icon img {
    width: 20px;
    height: 20px;
    filter: invert(1);
    transition: transform 0.3s ease;
}

.social-icons .icon:hover {
    background-color: #fff;
    border-color: #fff;
}

.social-icons .icon:hover img {
    transform: scale(1.2);
    filter: none;
}

.footer-content a {
    color: #f5f5f5;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-content a:hover {
    color: #02faf9;
    text-decoration: underline;
}

@media (max-width: 1400px) {
    .section {
        flex: 1 1 calc(50% - 20px);
    }
    .alignment-issue{
        display: none;
    }
    

}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }
    .alignment-issue {
        display: block;
      }
    .section-hide{
        display: none;
    }
    .section {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .vertical-line {
        display: none;
    }
}

@media (max-width: 480px) {
    .footer-container {
        padding: 0 10px;
    }

    .section {
        padding: 0 10px;
    }

    
    .contact-info {
        flex-direction: column;
        align-items: flex-start;
    }

    .contact-info img {
        margin-bottom: 5px;
    }
}

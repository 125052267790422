/* Workflow Section Styling */
.workflow-section {
  background: #f9f9f9;
  color: #333;
  text-align: center;
  padding: 5rem 2rem;
  border-radius: 15px;
}

/* Heading Styling */
.workflow-section h2 {
  color: #2d3748;
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  display: inline-block; /* Ensure inline-block for animation effects */
  transition: transform 0.3s ease; /* Transition for hover effect */
}

/* Hover Animation for Heading */
.workflow-section h2:hover {
  transform: translateY(-10px); /* Move up slightly on hover */
}

/* Heading Underline Styling */
.workflow-section h2::after {
  content: '';
  display: block;
  width: 150px;
  height: 8px;
  background: #3498db;
  margin: 1rem auto;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: width 0.3s ease; /* Transition for width */
  transform-origin: center; /* Ensure transform origin is centered */
}

/* Hover Animation for Underline */
.workflow-section h2:hover::after {
  width: 100%; /* Expand to full width on hover */
}

/* Bounce Animation for Heading */
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.workflow-section h2 {
  animation: bounce 1s ease infinite; /* Apply bounce animation */
}

/* Hover Animation Reset for Bounce */
.workflow-section h2:hover {
  animation: none; /* Remove bounce animation on hover */
  transform: translateY(-10px); /* Maintain slight move up on hover */
}


.workflow-section p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: #555;
}

.workflow-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 0 1rem;
}

.video-container {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
}

.workflow-video {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.workflow-video:hover {
  transform: scale(1.05);
}

.workflow-steps {
  flex: 1;
  max-width: 100%;
  text-align: left;
}

.workflow-step {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  padding: 2rem;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.workflow-step:hover {
  background: #f0f0f0;
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.workflow-step.expanded {
  background: #e0e0e0;
  transform: translateY(0);
}

.workflow-step-icon {
  font-size: 2.5rem;
  color: #007bff;
  transition: color 0.3s ease;
}

.workflow-step-icon:hover {
  color: #0056b3;
}

.workflow-step h3 {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 700;
  color: #333;
  transition: color 0.3s ease;
}

.workflow-step:hover h3 {
  color: #3498db;
}

.workflow-step p {
  margin: 0.5rem 0 0;
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .workflow-section h2 {
    font-size: 2.5rem;
  }

  .workflow-section p {
    font-size: 1.1rem;
  }

  .workflow-video {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .workflow-section h2 {
    font-size: 2rem;
  }

  .workflow-section p {
    font-size: 1rem;
  }

  .workflow-content {
    flex-direction: column;
    align-items: center;
  }

  .video-container {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .workflow-steps {
    max-width: 100%;
  }

  .workflow-step {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .workflow-step h3 {
    font-size: 1.6rem;
  }

  .workflow-step p {
    font-size: 0.95rem;
  }

  .workflow-step-icon {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .workflow-section h2 {
    font-size: 1.8rem;
  }

  .workflow-section p {
    font-size: 0.9rem;
  }

  .video-container {
    margin-bottom: 1.5rem;
  }

  .workflow-step {
    padding: 1rem;
  }

  .workflow-step h3 {
    font-size: 1.4rem;
  }

  .workflow-step p {
    font-size: 0.85rem;
  }

  .workflow-step-icon {
    font-size: 1.5rem;
  }
}

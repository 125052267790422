.blog-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.blog-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px; /* Added padding for better spacing */
  display: flex;
  flex-direction: column;
  align-items: center; 
  text-align: center; 
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 12px; /* Make the image corners rounded */
}

.blog-title {
  font-size: 1.25rem; /* Slightly larger font size */
  margin-top: 16px;
  margin-bottom: 8px;
  color: #333;
  font-weight: bold; 
}
.meta-description {
  padding: 12px;
 /* Light gray background */
  border-radius: 8px;
 margin-bottom: 16px;
  color: #4a5568; /* Darker gray text */
  font-size: 0.875rem; /* Smaller text size for description */
  line-height: 1.4; /* Improve readability */
}

.read-more-button {
  margin-top: 16px;
  padding: 10px 20px;
  color: #fff;
  background: linear-gradient(to right, #6b46c1, #4299e1); /* Gradient from purple to blue */
  border: none;
  border-radius: 9999px; /* Fully rounded button */
  cursor: pointer;
  transition: background 0.3s ease;
}

.read-more-button:hover {
  background: linear-gradient(to right, #5a3bb8, #3182ce); /* Darker gradient on hover */
}

.skeleton-card {
  background: #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
  padding: 16px;
  box-sizing: border-box;
}

.skeleton-thumbnail {
  width: 100%;
  height: 200px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-title {
  width: 60%;
  height: 20px;
  margin: 16px 0;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-description {
  width: 80%;
  height: 40px;
  margin: 16px 0;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-button {
  width: 120px;
  height: 40px;
  margin: 16px auto;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  border-radius: 50px;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.loading-container p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .blog-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns per row on tablets */
  }
}

@media (max-width: 768px) {
  .blog-container {
    grid-template-columns: 1fr; /* 1 column per row on mobile devices */
  }
}

@media (max-width: 480px) {
  .blog-container {
    grid-template-columns: 1fr; /* 1 column per row on mobile devices */
  }
}

/* App.css */
html, body, #root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/* Adjust margin to leave space for the footer */
main {
  flex: 1;
  margin-bottom: 60px; /* Adjust based on your footer height */
}

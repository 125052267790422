header {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    background: linear-gradient(180deg, #303f9f, #9c27b0);
    background-size: 100% 100%;
    position: relative;
    z-index: 1000;
    overflow-x: hidden;
  }
  
  .contact-info img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    filter: invert(1);
    transition: transform 0.3s ease;
}

.contact-info img:hover {
    transform: rotate(360deg);
}

  
  .logo img {
    height: 100px;
  }
  
 
  
  nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 30px;
  }
  
  nav li {
    margin: 0 1rem;
    padding: 90;
  }
  
  nav a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    position: relative;
    transition: color 0.3s ease;
  }
  
  nav a:hover {
    color: #02faf9; /* tealGreen color */
  }
   .active-link {
    color: #02faf9; /* tealGreen color */
  
  }
 
  
  nav a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #02faf9;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }
  
  nav a:hover::before {
    visibility: visible;
    transform: scaleX(1);
  }
  
  .menu-toggle {
    display: none;
    flex-direction: column;
    margin-top: 35px;
    cursor: pointer;
  }
  
  .menu-toggle .bar {
    height: 3px;
    width: 25px;
    background-color: white;
    margin: 4px 0;
    transition: all 0.3s ease;
  }
  
  .intro-text {
    position: absolute;
    bottom: 6rem;
    left: 3rem;
    color: white;
    animation: fadeInSlideUp 1s forwards;
  }
  
  .intro-text h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .intro-text h1 .white {
    color: white;
  }
  
  .intro-text h1 .gold {
    color: gold;
  }
  
  .intro-text p {
    font-size: 1rem;
    max-width: 600px;
    text-align: start;
    line-height: 3.2vh;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .intro-text h1 {
      font-size: 2.5rem;
    }
  
    .intro-text p {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 992px) {
    .intro-text {
      bottom: 8rem;
      left: 2rem;
    }
  
    .intro-text h1 {
      font-size: 2rem;
    }
  
    .intro-text p {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 768px) {
    .intro-text {
      bottom: 9rem;
      left: 2rem;
    }
  
    .intro-text h1 {
      font-size: 2rem;
    }
  
    .intro-text p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .intro-text {
      bottom: 10rem;
      left: 2rem;
    }
  
    .intro-text h1 {
      font-size: 1.6rem;
    }
  
    .intro-text p {
      font-size: 1rem;
      max-width: 80%;
    }
  }
  
  @media (max-width: 400px) {
    .intro-text {
      bottom: 10rem;
      left: 2rem;
    }
  
    .intro-text h1 {
      font-size: 1.75rem;
    }
  
    .intro-text p {
      font-size: 1rem;
      max-width: 75%;

    }
  }

  @media (max-width: 850px) {
    nav {
      position: absolute;
      top: 110px;
      left: 0;
      width: 100%;
      background: black;
      flex-direction: column;
      align-items: center;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;
    }
  
    nav.open {
      max-height: 300px;
    }
  
    nav ul {
      flex-direction: column;
      width: 100%;
    }
  
    nav li {
      width: 100%;
      text-align: center;
      margin: 1rem 0;
    }
  
    .menu-toggle {
      display: flex;
    }
  
    .menu-toggle.open .bar:nth-child(1) {
      transform: rotate(-45deg) translate(-10px, 6px);
    }
  
    .menu-toggle.open .bar:nth-child(2) {
      opacity: 0;
    }
  
    .menu-toggle.open .bar:nth-child(3) {
      transform: rotate(45deg) translate(-9px, -6px);
    
    }
   
  }

  @keyframes fadeInSlideUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .intro-text h1,
  .intro-text p {
    opacity: 0;
    animation: fadeInSlideUp 1s forwards;
  }
  
  .intro-text h1 {
    animation-delay: 0.5s; /* delay for h1 */
  }
  
  .intro-text p {
    animation-delay: 1s; /* delay for paragraph */
  }
  

  


/* Contact Data Container */
.contact-data {
    padding: 4rem 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

/* Contact Information Styling */
.contact-information {
    flex: 1;
    max-width: 350px; /* Reduced max-width */
    padding: 1.5rem; /* Reduced padding */
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.contact-information:hover {
    transform: translateY(-10px); /* Lift card on hover */
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

/* Information Item Styling */
.information-item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    transition: transform 0.3s ease;
}

.information-item:hover {
    transform: scale(1.02); /* Slightly enlarge on hover */
}

.information-icon {
    font-size: 24px;
    color: #008080;
    margin-right: 1rem; /* Space between icon and text */
    flex-shrink: 0; /* Prevent icon from shrinking */
}

.information-text {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    flex: 1; /* Allow text to take remaining space */
}

/* Links Styling */
.information-text a {
    text-decoration: none; /* Remove underline */
    color: #008080; /* Match with the icon color */
    transition: color 0.3s ease; /* Smooth color transition */
}

.information-text a:hover {
    color: #004d40; /* Darker color on hover */
}

/* Contact Form Container */
.contact-form-container {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%; /* Ensure it doesn't overflow */
}

/* Contact Form Styling */
.contact-form {
    background: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    max-width: 500px; /* Adjusted for a more balanced look */
    width: 100%;
    animation: fadeInUp 1s ease-out;
}

/* Contact Form Heading */
.contact-form h2 {
    font-size: 24px;
    margin-bottom: 1.5rem;
    color: #333;
    animation: fadeIn 1.2s ease-out;
    text-align: center; /* Center the heading */
}

/* Form Group Styling */
.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    font-size: 16px;
    color: #555;
    margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px;
    border: 2px solid #ddd;
    border-radius: 8px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 16px;
}

.form-group input:focus,
/* Form Group Textarea Focus Styling */
.form-group textarea:focus {
    border-color: #303f9f; /* Match the button color */
    box-shadow: 0 0 8px rgba(48, 63, 159, 0.2); /* Similar to button color */
}

/* Submit Button Styling */
.submit-button {
    background: linear-gradient(180deg, #303f9f, #9c27b0);
    color: #ffffff;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    animation: pulse 1s infinite;
    display: block; /* Ensure the button is block-level */
    margin: 0 auto; /* Center the button */
}

.submit-button:hover {
    background: linear-gradient(180deg, #303f9f, #9c27b0); /* Gradient for hover */
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Keyframes for Animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(48, 63, 159, 0.7); /* Match button color */
    }
    50% {
        box-shadow: 0 0 0 10px rgba(48, 63, 159, 0); /* Match button color */
    }
    100% {
        box-shadow: 0 0 0 0 rgba(48, 63, 159, 0); /* Match button color */
    }
}


/* Existing styles for project details */
.project-detail {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .project-detail-image {
    width: 100%;
    text-align: center;
  }
  
  .project-detail-image img {
    max-width: 100%;
    height: auto;
  }
  
  .project-detail-content {
    padding: 10px;
  }
  
  .project-detail-content h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #2d3748;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    display: inline-block; /* Ensure inline block to apply animation */
    transition: transform 0.3s ease; /* Transition for hover effect */
  }
  
  .project-detail-content h2:hover {
    transform: translateY(-10px); /* Move up slightly on hover */
  }
  
  .project-detail-content h2::after {
    content: '';
    display: block;
    width: 120px;
    height: 6px;
    background: #3498db;
    margin: 1rem auto;
    border-radius: 5px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transition: width 0.3s ease; /* Transition for width */
    transform-origin: center; /* Ensure transform origin is centered */
  }
  
  .project-detail-content h2:hover::after {
    width: 100%; /* Expand to full width on hover */
  }
  
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
  }
  
  .project-detail-content h2 {
    animation: bounce 1s ease infinite; /* Apply bounce animation */
  }
  
  .project-detail-content h2:hover {
    animation: none; /* Remove bounce animation on hover */
    transform: translateY(-10px); /* Maintain slight move up on hover */
  }
  
  /* Divider Styling */
  .divider {
    border-top: 1px solid red; /* Light gray border for the divider */
    margin: 20px 0; /* Margin above and below the divider */
  }
  
  /* Responsive Styles */
  @media (min-width: 600px) {
    .projects-section {
      padding: 40px;
    }
  
    .project-detail {
      flex-direction: row;
      align-items: center;
    }
  
    .project-detail-image, .project-detail-content {
      flex: 1;
    }
  
    .project-detail-content {
      padding: 20px;
    }
  
    .project-detail-content h2 {
      font-size: 1.75em;
    }
  
    .project-detail-content p {
      font-size: 1.125em;
    }
  }
  
  @media (min-width: 900px) {
    .projects-section {
      padding: 60px;
    }
  
    .project-detail-content {
      padding: 30px;
    }
  
    .project-detail-content h2 {
      font-size: 2em;
    }
  
    .project-detail-content p {
      font-size: 1.25em;
    }
  }
  
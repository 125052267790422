/* General Styling */
.about-content {
  padding: 2rem 1rem; /* Adjusted padding for smaller screens */
  max-width: 100%;
  margin: auto;
  background: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Section Styling */
.about-content section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
  margin-bottom: 2rem; /* Increased margin between sections */
}

.about-content section.appear {
  opacity: 1;
  transform: translateY(0);
}

/* Heading Styling */
.about-content h2 {
  color: #2d3748;
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  display: inline-block; /* Ensure inline-block for animation effects */
  transition: transform 0.3s ease; /* Transition for hover effect */
  animation: bounce 1s ease infinite; /* Apply bounce animation */
}

/* Hover Animation for Heading */
.about-content h2:hover {
  transform: translateY(-10px); /* Move up slightly on hover */
  animation: none; /* Remove bounce animation on hover */
}

/* Heading Underline Styling */
.about-content h2::after {
  content: '';
  display: block;
  width: 150px;
  height: 8px;
  background: #3498db;
  margin: 1rem auto 0; /* Add space between text and underline */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: width 0.3s ease; /* Transition for width */
  transform-origin: center; /* Ensure transform origin is centered */
}

/* Hover Animation for Underline */
.about-content h2:hover::after {
  width: 100%; /* Expand to full width on hover */
}

/* Bounce Animation */
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

/* Fade-In Up Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Paragraph Styling */
.about-content p {
  color: #555; /* Adjusted text color for better readability */
  line-height: 1.6;
  font-size: 1rem; /* Adjusted font size for paragraphs */
  margin-bottom: 1.5rem; /* Adjusted margin between paragraphs */
}

/* Tablets (landscape and portrait) */
@media (min-width: 768px) {
  .about-content {
    padding: 3rem 2rem; /* Adjusted padding for tablets */
  }

  .about-content h2 {
    font-size: 2rem; /* Increased font size for headings on tablets */
  }

  .about-content p {
    font-size: 1.1rem; /* Increased font size for paragraphs on tablets */
  }
}

/* Laptops and Desktops */
@media (min-width: 1024px) {
  .about-content {
    padding: 4rem 3rem; /* Adjusted padding for laptops and desktops */
  }

  .about-content h2 {
    font-size: 2.5rem; /* Increased font size for headings on larger screens */
  }

  .about-content p {
    font-size: 1.2rem; /* Increased font size for paragraphs on larger screens */
  }
}

/* Large Desktops */
@media (min-width: 1440px) {
  .about-content {
    max-width: 1200px; /* Limit maximum width for large screens */
    padding: 5rem 4rem; /* Adjusted padding for large screens */
  }
}

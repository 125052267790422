/* Testimonial Section Styling */
.testimonial-section {
  padding: 3rem 2rem;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* Heading Styling */
.testimonial-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #2d3748;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  display: inline-block; /* Ensure inline block to apply animation */
  transition: transform 0.3s ease; /* Transition for hover effect */
}

/* Hover Animation for Heading */
.testimonial-section h2:hover {
  transform: translateY(-10px); /* Move up slightly on hover */
}

/* Heading Underline Styling */
.testimonial-section h2::after {
  content: '';
  display: block;
  width: 120px;
  height: 6px;
  background: #3498db;
  margin: 1rem auto;
  border-radius: 5px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transition: width 0.3s ease; /* Transition for width */
  transform-origin: center; /* Ensure transform origin is centered */
}

/* Hover Animation for Underline */
.testimonial-section h2:hover::after {
  width: 100%; /* Expand to full width on hover */
}

/* Bounce Animation for Heading */
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.testimonial-section h2 {
  animation: bounce 1s ease infinite; /* Apply bounce animation */
}

/* Hover Animation Reset for Bounce */
.testimonial-section h2:hover {
  animation: none; /* Remove bounce animation on hover */
  transform: translateY(-10px); /* Maintain slight move up on hover */
}


.testimonial-slider {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.testimonial-slides {
  display: flex;
  transition: transform 0.6s ease-in-out;
}

.testimonial-card {
  min-width: 100%;
  padding: 2rem;
  text-align: center;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.client-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1.5rem;
  border: 3px solid #3498db;
  transition: border-color 0.3s ease;
}

.client-image:hover {
  border-color: #005f5f;
}

.testimonial-card h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 600;
}

.client-role {
  font-size: 1rem;
  color: #777;
}

.client-quote {
  font-size: 1.1rem;
  color: #444;
  font-style: italic;
  margin-top: 1rem;
  line-height: 1.6;
}

/* Navigation Buttons */
.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.6rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 10;
}


.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.nav-btn:hover {
  background-color: #2980b9;
  transform: scale(1.1);
}

.prev {
  left: 15px;
}

.next {
  right: 15px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .testimonial-section h2 {
    font-size: 2.2rem;
  }

  .testimonial-card {
    padding: 1.5rem;
  }

  .client-image {
    width: 70px;
    height: 70px;
  }

  .testimonial-card h3 {
    font-size: 1.3rem;
  }

  .client-quote {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .testimonial-section h2 {
    font-size: 1.8rem;
  }

  .testimonial-card {
    padding: 1rem;
  }

  .client-image {
    width: 60px;
    height: 60px;
  }

  .testimonial-card h3 {
    font-size: 1.1rem;
  }

  .client-quote {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .testimonial-section h2 {
    font-size: 1.5rem;
  }

  .testimonial-card {
    padding: 0.8rem;
  }

  .client-image {
    width: 50px;
    height: 50px;
  }

  .testimonial-card h3 {
    font-size: 1rem;
  }

  .client-quote {
    font-size: 0.8rem;
  }
}

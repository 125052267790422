/* General Styling */
.projects-section, .clients-section {
  padding: 5rem 2rem;
  background: linear-gradient(135deg, #f0f4f8, #e2e8f0);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #2d3748;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  display: inline-block;
  transition: transform 0.3s ease;
}

.heading:hover {
  transform: translateY(-10px);
}

.heading::after {
  content: '';
  display: block;
  width: 120px;
  height: 6px;
  background: #3498db;
  margin: 1rem auto;
  border-radius: 5px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transition: width 0.3s ease;
  transform-origin: center;
}

.heading:hover::after {
  width: 100%;
}

/* Bounce Animation for Heading */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.heading {
  animation: bounce 1s ease infinite;
}

.heading:hover {
  animation: none;
  transform: translateY(-10px);
}

.projects-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  padding: 0 1rem;
}

.project-card {
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 280px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 12px;
  transition: transform 0.4s ease, box-shadow 0.4s ease, border 0.4s ease;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  background: #fff;
  border: 2px solid transparent;
}

.project-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 0.4s ease;
  opacity: 0;
  z-index: 1;
}

.project-card:hover::before {
  opacity: 0.3;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.4);
  border: 2px solid #3498db;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.4s ease, transform 0.4s ease;
  filter: brightness(0.75);
}

.project-card:hover .thumbnail {
  filter: brightness(1);
  transform: scale(1.1);
}

.project-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  opacity: 0;
  transform: translateY(20%);
  transition: opacity 0.4s ease, transform 0.4s ease;
  z-index: 2;
}

.project-card:hover .project-info {
  opacity: 1;
  transform: translateY(0);
}

.project-title {
  margin: 0.5rem 0.5rem 0.5rem;
  padding: 0 1rem; /* Adjust the padding value as needed */
    font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
  text-align: left;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.project-card:hover .project-title {
  color: #3498db;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
}

.project-description {
  margin: 0.5rem 0.5rem 0.5rem;
  padding: 0 1rem; /* Adjust the padding value as needed */
  font-size: 1.2rem;
  line-height: 1.5;
  transition: color 0.3s ease;
  text-align: start;
}

.project-card:hover .project-description {
  color: #e2e8f0;
}


/* Responsive Design */
@media (max-width: 1024px) {
  .heading {
    font-size: 3rem;
  }

  .projects-grid {
    gap: 1.5rem;
  }

  .project-card {
    max-width: 320px;
  }

  .project-title {
    font-size: 1.6rem;
  }

  .project-description {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.6rem;
  }

  .project-card {
    max-width: 100%;
    height: 240px;
  }

  .project-title {
    font-size: 1.4rem;
  }

  .project-description {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 2rem;
  }

  .project-card {
    height: 220px;
  }

  .project-title {
    font-size: 1.2rem;
  }

  .project-description {
    font-size: 0.9rem;
  }
}

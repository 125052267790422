/* Container Styling */
.blog-detail-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    animation: fadeIn 1s ease-out;
  }
  
  /* Header Styling */
  .blog-detail-header {
    position: relative;
    margin-bottom: 20px;
    animation: slideIn 1s ease-out;
  }
  
  .blog-detail-thumbnail {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .blog-detail-thumbnail:hover {
    transform: scale(1.05);
  }
  
  .blog-detail-meta {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: linear-gradient(180deg, #303f9f, #9c27b0);
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    transition: background 0.3s ease;
  }
  
  .blog-detail-meta:hover {
    background: linear-gradient(180deg, #9c27b0, #303f9f); /* Reverse gradient on hover */
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
    transform: translateY(-2px); /* Slightly lift the button on hover */
  }
  
  .blog-detail-title {
    font-size: 2.5rem;
    margin: 0;
    animation: fadeInUp 1s ease-out;
  }
  
  .blog-detail-author {
    font-size: 1rem;
    color: #ddd;
  }
  
  /* Main Content Styling */
  .blog-detail-content {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-out;
  }
  
  /* Sidebar Styling */
  .blog-detail-sidebar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    animation: slideInRight 1s ease-out;
  }
  
  .author-info, .related-posts {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .author-info:hover, .related-posts:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .author-info img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .author-info img:hover {
    transform: scale(1.1);
  }
  
  .related-posts ul {
    list-style-type: none;
    padding: 0;
  }
  
  .related-posts li {
    margin-bottom: 10px;
  }
  
  .related-posts a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .related-posts a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  /* Footer Styling */
  .blog-detail-footer {
    margin-top: 20px;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-out;
  }
  
  .comment-box {
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    resize: vertical;
    transition: border-color 0.3s ease;
  }
  
  .comment-box:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .comment-submit {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .comment-submit:hover {
    background-color: #0056b3;
  }
  
  /* Error Styling */
  .blog-detail-error {
    text-align: center;
    font-size: 1.5rem;
    color: #ff0000;
    padding: 20px;
  }
  .shimmer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
  }
  
  .shimmer {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
    border-radius: 8px;
  }
  
  .shimmer.thumbnail {
    width: 100%;
    height: 300px;
    animation: pulse 2s infinite;
  }
  
  .shimmer.title {
    width: 60%;
    height: 30px;
    margin-top: 20px;
    animation: pulse 2s infinite;
  }
  
  .shimmer.text {
    width: 100%;
    height: 200px;
    animation: pulse 2s infinite;
  }
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes slideIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes slideInRight {
    from { opacity: 0; transform: translateX(20px); }
    to { opacity: 1; transform: translateX(0); }
  }
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
  /* Pulse animation */
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  /* Responsive Design */
  @media (max-width: 768px) {
    .blog-detail-header {
      text-align: center;
    }
  
    .blog-detail-meta {
      position: static;
      background: none;
      padding: 0;
      color: #000;
    }
  
    .blog-detail-title {
      font-size: 2rem;
    }
  
    .blog-detail-sidebar {
      display: block;
    }
  
    .author-info, .related-posts {
      margin-bottom: 20px;
    }
  }
  